import CryptoJS from 'crypto-js'

export const encryptSecretKeys = (data, encryptionKey) => {
  const jsonString = JSON.stringify(data)
  const encrypted = CryptoJS.AES.encrypt(jsonString, encryptionKey).toString()
  return encrypted
}

export const decryptSecretKeys = (encryptedData, encryptionKey) => {
  try {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey)
    const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8)
    const decrypted = typeof decryptedString === 'string' ? JSON.parse(decryptedString) : decryptedString
    return decrypted
  } catch (error) {
    console.error('Error decrypting JSON:', error)
    return null
  }
}
// this functions generate salt from client side
export const generateSalt = (bytes) => CryptoJS.randomBytes(bytes)
